var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "overlay-opacity": _vm.globalDialogAction.includes("logout") ? 1 : 0.5,
        persistent: "",
        "max-width": "500",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center secondary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [_vm._v("error")]),
                _vm._v(" " + _vm._s(_vm.globalDialogTitle) + " "),
              ],
              1
            ),
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "mt-5" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.globalDialogText) },
            }),
            _vm.globalDialogTaskId || _vm.task
              ? _c("div", [
                  _c("div", { staticClass: "my-2" }, [
                    _vm._v("Status: " + _vm._s(_vm.taskStatusText)),
                  ]),
                  _vm.isRunningTask
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-center align-center",
                          staticStyle: { width: "100%", height: "100%" },
                        },
                        [
                          _vm.isRunningTask
                            ? _c("v-progress-circular", {
                                staticClass: "ml-2",
                                attrs: { color: "primary", indeterminate: "" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm.isCompletedTask
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-center align-center",
                          staticStyle: { width: "100%", height: "100%" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "success" } },
                            [_vm._v("done")]
                          ),
                        ],
                        1
                      )
                    : _vm.isErrorTask
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-center align-center w-100",
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { large: "", color: "error" } },
                              [_vm._v("error")]
                            ),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "my-2",
                          domProps: { innerHTML: _vm._s(_vm.taskResult) },
                        }),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.globalDialogAction.includes("returnToWelcomePage") ||
              _vm.globalDialogAction.includes("refreshInstanceSnapshots")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: {
                        color: "primary",
                        disabled: _vm.fetchingSnapshots,
                        text: "",
                      },
                      on: { click: _vm.goToWelcomePage },
                    },
                    [_vm._v(" go to dashboard ")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("logout")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("logout")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("dismiss")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.decline()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("cancel")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.decline()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("back")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.decline()
                        },
                      },
                    },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("close")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("reload")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.reloadPage },
                    },
                    [_vm._v("Reload")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("refreshInstanceSnapshots")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: {
                        loading: _vm.fetchingSnapshots,
                        color: "primary",
                        text: "",
                      },
                      on: { click: _vm.refreshInstanceSnapshots },
                    },
                    [_vm._v(" refresh ")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("accept")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.accept()
                        },
                      },
                    },
                    [_vm._v("Accept")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("confirm")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.accept()
                        },
                      },
                    },
                    [_vm._v("Confirm")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("stop-application")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: {
                        color: "error",
                        text: "",
                        loading: _vm.stoppingApp,
                        "e2e-global-stop": "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.stopApp()
                        },
                      },
                    },
                    [_vm._v(" Stop ")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("restart-application")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.reloadPage },
                    },
                    [_vm._v(" Restart ")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("delete")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.accept },
                    },
                    [_vm._v("Delete")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("toInstanceSnapshots")
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.toInstanceSnapshots },
                    },
                    [_vm._v(" Restore a snapshot from archive ")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("toSnapshotFiles")
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.toSnaphostFiles },
                    },
                    [_vm._v("Delete some files")]
                  )
                : _vm._e(),
              _vm.globalDialogAction.includes("contactSupport")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "primary" },
                      on: { click: _vm.openIntercom },
                    },
                    [_vm._v(" Contact Support ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }